.appWrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
}

header {
  width: 100%;
  background-color: var(--appHeaderBG);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--layoutBorder);
  height: 8vh;
  padding: 0 20px;
  z-index: 999 !important;
}

section {
  display: flex;
  flex-direction: row;
  /* height: 92.5vh;
  overflow: hidden; */
}

.mainLayout {
  height: 100vh;
  width: 100%;
  min-width: 85%;
  /* background-color: var(--mainLayoutBG); */
  background: #f9f9fc;
  position: relative;
}

.campaignCreateWrapper {
  position: absolute;
  bottom: 40px;
  right: 20px;
  z-index: 999 !important;
}

.CreateButtonWrapper {
  position: relative;
  align-self: right;
}

/* header */

.header-container {
  width: 18vw;
}

.header-appicon {
  border-left: 1px solid var(--border-100);
  height: 40px;
  width: 62vw;
  padding: 0 5px;
}

.appIcon {
  width: 8vw;
  font-size: 2vw;
  font-weight: 700;
  color: var(--textBlack);
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.appIcon > img {
  width: 30vw;
  height: 5vh;
}

.appName-main {
  width: 21%;
}

.appName {
  font-size: 1.2vw;
  font-weight: 700;
  color: var(--textBlack);
  height: 25px;
}

.appName1 {
  color: var(--primary);
  font-size: 0.77vw;
  font-weight: 500;
}

.header-line {
  background-color: white;
  box-shadow: 0px 4px 6px 0px #0000000a;
  border-radius: 6px;
  /* padding: 10px; */
}

.notification {
  width: 25px;
  height: 25px;
}

.notificationActive {
  width: 40px;
  height: 40px;
}

.alignRight {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

/* sidebar */
nav {
  background-color: var(--appHeaderBG);
  /* padding: 15px 10px; */
  display: flex;
  justify-content: start;
}

.nav-open {
  height: 100vh;
  padding: 15px 10px;
  transition: width 0.5s ease-in-out;
  width: 30%;
}

.nav-close {
  height: 100vh;
  padding: 15px 10px;
  transition: width 0.5s ease-in-out;
  width: 5%;
  overflow: hidden;
  white-space: nowrap;
}

.navBox {
  width: 100%;
  /* height: 55px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  gap: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.navName {
  font-size: 0.9rem !important;
  font-weight: 400;
}

.navIcon {
  width: 19px;
  height: 19px;
}

.freePlanBackground {
  background-color: #0cc998;
  border-radius: 8px;
  padding: 6px 10px;
  color: white;
  font-size: 0.75vw;
  border-radius: 6px;
  font-weight: 400;
}

.growthPlanBackground {
  background-color: #388ec3;
  border-radius: 8px;
  padding: 6px 10px;
  color: white;
  font-size: 0.75vw;
  border-radius: 6px;
  font-weight: 400;
}

.enterprisePlanBackground {
  background-color: #565ce0;
  border-radius: 8px;
  padding: 6px 10px;
  color: white;
  font-size: 0.75vw;
  border-radius: 6px;
  font-weight: 400;
}

.business-wrapper {
  width: 50%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px;
}

.buttons {
  margin: 0px 15px;
  color: white;
  font-size: 0.729vw;
  font-weight: 600;
  width: 8%;
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-image: linear-gradient(to right, #0acd95, #6940f2 40%);
}

.buttons > img {
  width: 12px;
  height: 12px;
}

.usage-plan {
  width: 100%;
  border-radius: 6px;
  padding: 8px 10px;
  /* background: linear-gradient(90deg, #0acd95 35.24%, #6940f2 100%); */
  background-color: var(--new-primary);
}

.usage-plan-img {
  width: 14px;
  height: 14px;
}

.usage-plan > p {
  width: 100%;
  color: #fff;
  font-size: 0.729vw;
  font-weight: 600;
  text-align: center;
}

.log_out {
  padding: 10px;
}
.side_bar_container {
  background-color: #f9f9fc;
  z-index: 999;
}

.side_bar_container .log_out:hover {
  background-color: var(--channelButton);
  padding: 10px;
  border-radius: 6px;
}

.nav-close .active-listText1 {
  /* padding: 10px; */
  width: 50px;
}

.nav-close .navBox:not(.active-listText1):hover {
  width: 50px;
}

.active-listText1 {
  width: 100%;
  background-color: var(--new-primary) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  color: var(--white) !important;
  padding: 8px 15px;
}

.navBox:not(.active-listText1):hover {
  background-color: var(--border-input);
  color: var(--textBlack) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 8px 15px;
}

.navBox:not(.active-listText1) {
  color: var(--font-600) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 8px 15px;
}
