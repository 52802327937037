/* .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
} */
/* .ant-checkbox-disabled .ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
} */
/* .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
} */
/* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary) !important;
} */

.checkboxContent {
  font-size: 0.63vw;
  font-weight: 400;
}

.text {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--textBlack);
  margin-top: 4px;
}

.textGreen {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--primary);
  cursor: pointer;
  margin-top: 4px;
}

.mb {
  margin-bottom: 50px;
}

.antdButton {
  font-weight: 500;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02rem;
  color: var(--background) !important;
  background-color: var(--primary) !important;
  margin: 30px 0 20px 0;
  font-size: 0.8vw;
  padding: 10px 20px;
}

/* Signup step 3 */

.step3Sentence {
  margin-top: 30px !important;
  color: var(--darkgrey);
  font-weight: 400;
  font-size: 0.7vw;
  margin: 0;
}

.step3Email {
  margin-top: 8px !important;
  color: var(--darkgrey);
  font-weight: 400;
  font-size: 0.7vw;
  margin: 0;
}

.redirect {
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0 0 0;
}

.redirect > a {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--textBlack);
}

.timing {
  display: flex;
  flex-direction: row;
}

.timing > a {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--textBlack);
}

.timing > p {
  font-size: 0.7vw;
  font-weight: 600;
  color: var(--primary);
  margin-right: 10px;
  margin-top: 4px;
}

.step2Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
  height: auto !important;
}

.step2Wrapper-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: auto !important;
}

/* input#Email.storybook-input {
  background: red !important;
} */
/* input#password.storybook-input {
  background-color: none !important;
} */

/* Forget-Password */

.stepper-wrapper {
  border-radius: 16px;
  background-color: #fbfbfc;
  padding: 13px 18px;
}

.uploadStep-image {
  border-radius: 10px;
  background-color: #f4f6f8;
  width: 50px;
  height: 50px;
}

.uploadStep-image2 {
  border-radius: 10px;
  background-color: #f4f6f8;
  width: 60px;
  height: 60px;
}
.uploadStep-profile-image {
  border-radius: 10px;
  background-color: #f4f6f8;
  width: 70px;
  height: 70px;
}

.upload-image-before {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-content {
  padding-left: 10px;
}

.account-image {
  font-size: 0.975rem;
  font-weight: 500;
  padding-bottom: 4px;
  color: var(--textBlack);
}

.recommended-file {
  font-size: 0.75rem;
  font-weight: 400;
  color: #616874;
}

.upload-btn {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--background);
  background-color: var(--new-primary);
  border-radius: 6px;
  padding: 6px 16px;
  letter-spacing: 0.2px;
}

.get-otp {
  font-size: 0.75vw;
  color: #0acd95;
  font-weight: 600;
}

.resend-code {
  font-size: #2d3036;
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 0.75vw;
}

.otp-verify {
  font-size: #2d3036;
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 0.85vw;
}

.create-account {
  font-weight: 600;
  font-size: 0.85vw;
  padding: 10px 20px;
  border-radius: 10px;
  /* color: #898e99; */
}

.brand-container {
  height: 68vh;
  overflow-y: scroll;
}

.brand-btn {
  /* background-color: #0acd95 !important; */
  padding: 9px 22px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  font-size: 0.85vw;
  letter-spacing: 0.2px;
}

.brand-select {
  width: 30vw !important;
  border: 1px solid var(--card-border);
  border-radius: 6px;
}

.stepper-brand-popover {
  width: 30vw;
  padding: 10px 20px;
  max-height: 35vh;
  overflow-y: scroll;
}

.category-content {
  font-size: 0.75vw;
  font-weight: 600;
}

.category-dropdown:hover {
  background-color: var(--channelButton);
  border-radius: 6px;
}

.page-tags {
  font-size: 0.75vw;
  font-weight: 400;
}

.page-tags.placeholder-text {
  color: var(--placeholder);
}

.cursor-not-allowed {
  cursor: none;
}

.sign-content {
  font-size: 0.65vw;
  font-weight: 500;
  color: #2d3036;
}
.error {
  font-size: 0.65vw;
  font-weight: 400;
  color: var(--red);
}
.Button_name {
  padding: 10px 45px;
  border-radius: 8px;
  color: var(--background);
  font-size: 0.85vw;
  font-weight: 600;
}
.sign_up_login {
  font-size: 0.85vw;
  font-weight: 400;
}
.sign_up_login > span {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--a);
}

/* //Resend */

.Resend_container > span {
  font-size: 0.85vw;
  font-weight: 400;
  color: var(--textBlack);
}
.Example_email {
  padding: 5px 0px;
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--textBlack);
}
.change_email {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--BrandLink);
}
.resend_button {
  margin: 5px 0px;
  padding: 10px 35px;
  border-radius: 6px;
  color: var(--background);
  font-size: 0.85vw;
  font-weight: 600;
}
.resend_count {
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--darkgrey);
}
.Back {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--font-400);
}

.no-arrows {
  border-radius: 4px;
  width: 4vw;
  margin-inline-start: 10px;
  border: 1px solid var(--card-border);
  padding: 5px;
  font-size: 0.75vw;
  font-weight: 400;
  text-align: center;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Alert {
  width: 12px;
  height: 12px;
}
.Back_icon {
  width: 22px;
}
.otp_mainA {
  margin: 10px 0px;
}
.otp_box {
  padding: 10px 25px;
  border: 1px solid #e8e8ea;
  border-radius: 10px;
}
.otp_box > p {
  color: #898e99;
  font-size: 0.75vw;
  font-weight: 400;
}

.opacity {
  opacity: 0.2;
}
/* stepper -3 */

.stepper-container {
  width: 100%;
  display: flex;
  margin: 50px 0px;
}
.stepper-container > p {
  font-size: 0.85vw;
  font-weight: 500;
  /* margin-left: 6vw; */
}
.stepper-wrapper-content {
  /* border: 1px solid var(--font-400); */
  padding: 9px 10px;
  margin: auto;
  border-radius: 10px;
}
.stepper-wrapper-content > div > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: #898e99;
}
.stepper-wrapper-content > div > img {
  width: 15px;
  /* height: 15px; */
}
.brands > p {
  font-size: 0.75vw;
  font-weight: 500;
  color: #616874;
}
.input-with-icon1 {
  font-size: 0.75vw;
  font-weight: 400;
}
.adding > p {
  font-size: 0.75vw;
  font-weight: 500;
  margin: 15px 0px;
}
.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-left: 25px;
}

.input-icon {
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  width: 20px;
  height: auto;
}
.stepper_over > div {
  height: auto;
  max-height: 25vh;
  /* padding: 20px; */
}

.backicon {
  transform: rotate(180deg);
}
.go_to_dashboard {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--font-400);
}
.Account_icon {
  width: 35px;
}

.asp-modal-linear {
  background: linear-gradient(
    115.45deg,
    rgba(10, 205, 149, 0.16),
    rgba(105, 64, 242, 0.16)
  );
  background-color: #ffffff;
  box-shadow: 0px 4px 100px 0px #ffffff3d;
}
