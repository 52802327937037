.forgotPassword {
  /* width: 100%; */
  text-align: right;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--new-primary);
  margin-top: 7px;
  cursor: pointer;
}

.sign {
  font-size: 0.85vw;
  font-weight: 500;
}
.sign-up {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--a);
}
.sign_up_wrapper {
  
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    height: auto !important;
  }

.sign_up_wrapper > p {
  font-size: 1.85vw;
  font-weight: 600;
}
