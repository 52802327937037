.label {
  font-size: 0.813rem;
  font-weight: 500;
  /* margin-bottom: 4px; */
  margin: 10px 0px;
}
.required {
  color: var(--red);
  margin-left: 2px;
}
