.edges,
.newEdge {
  position: absolute;
}

.edges svg,
.newEdge svg {
  overflow: visible;
  position: absolute;
  pointer-events: none;
}

svg:not(:root) {
  overflow-clip-margin: content-box;
}
