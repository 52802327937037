.storybook-Title-container {
  width: 100%;
  text-align: center;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100px; */
}
.storybook-Title-container > img {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.storybook-Title-container-left {
  width: auto;
}

.Title-title {
  color: var(--title);
  font-size: 1.9vw;
  font-weight: 700;
}

.Title-content {
  width: 60%;
  color: var(--textBlack);
  /* color: #333843c9; */
  font-size: 1.2vw;
  font-weight: 700;
  margin-top: 24px !important;
  text-align: center;

  /* margin: 0px !important; */
}

.Title-paragraph {
  font-size: 0.975rem;
  font-weight: 400;
  color: var(--font-400);
  margin: 5px !important;
  letter-spacing: 0.3px;
}

.storybook-Title:focus {
  outline: none;
}

.Title--small {
  font-size: 0.7vw;
  font-weight: 600;
  color: var(--textBlack);
  /* margin: 0px 25px 0px 10px; */
}
.Title--medium {
  font-size: 0.9vw;
  font-weight: 600;
  color: var(--textBlack);
}
.Title--large {
  font-size: large;
  font-weight: bold;
  color: var(--textBlack);
}

.description--small {
  font-size: 0.4vw;
  font-weight: 400;
  color: var(--darkgrey);
  margin: 0 !important;
}
.description--medium {
  font-size: 0.78vw;
  font-weight: 400;
  color: var(--darkgrey);
  margin: 0 !important;
}
.description--large {
  font-size: 0.8vw;
  font-weight: 400;
  color: var(--darkgrey);
  margin: 0 !important;
}
