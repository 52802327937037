/* 28px */

.font28px-600 {
  font-size: 1.75rem;
  font-weight: 600;
}
/* 24px */

.font24px-600 {
  font-size: 1.375rem;
  font-weight: 600;
}
.font24px-500 {
  font-size: 1.5rem;
  font-weight: 500;
}
/* 22px - 600 */

.font22px-600 {
  font-size: 1.375rem;
  font-weight: 600;
}
.font20px-600 {
  font-size: 1.25rem;
  font-weight: 600;
}
.font20px-500 {
  font-size: 1.25rem;
  font-weight: 500;
}

/* 18px */

.font18px-500 {
  font-size: 1.063rem;
  font-weight: 500;
}
.font18px-600 {
  font-size: 1.063rem;
  font-weight: 600;
}
.font18px-400 {
  font-size: 1.063rem;
  font-weight: 400;
}

/* 16px - 500 */
.font16px-600 {
  font-size: 0.938rem;
  font-weight: 600;
}
.font16px-500 {
  font-size: 0.938rem;
  font-weight: 500;
}
.font16px-400 {
  font-size: 0.938rem;
  font-weight: 400;
}
/* 14px */
.font14px-600 {
  font-size: 0.813rem;
  font-weight: 600;
}
.font14px-500 {
  font-size: 0.813rem;
  font-weight: 500;
}
.font14px-400 {
  font-size: 0.813rem;
  font-weight: 400;
}
.font15px-400 {
  font-size: 0.875rem;
  font-weight: 400;
}
.font15px-500 {
  font-size: 0.875rem;
  font-weight: 500;
}
.font15px-600 {
  font-size: 0.875rem;
  font-weight: 600;
}

/* 13px - 400 */

.font13px-400 {
  font-size: 0.75rem;
  font-weight: 400;
}
.font13px-500 {
  font-size: 0.75rem;
  font-weight: 500;
}
.font13px-600 {
  font-size: 0.75rem;
  font-weight: 600;
}

/* 12px */

.font12px-600 {
  font-size: 0.688rem;
  font-weight: 600;
}
.font12px-500 {
  font-size: 0.688rem;
  font-weight: 500;
}
.font12px-400 {
  font-size: 0.688rem;
  font-weight: 400;
}
.font12px-300 {
  font-size: 0.688rem;
  font-weight: 300;
}

/* 11px */
.font11px-400 {
  font-size: 0.625rem;
  font-weight: 400;
}
/* buttons */

/* 16px */
.button-primary2 {
  background: var(--new-primary);
  color: var(--white);
  font-size: 0.938rem;
  font-weight: 600;
  border-radius: 6px;
}

/* 15px */
.button-primary {
  background: var(--new-primary);
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 6px;
}

/* 14px */
.button-primary1 {
  background: var(--new-primary);
  color: var(--white);
  font-size: 0.813rem;
  font-weight: 600;
  border-radius: 4px;
}

/* error-message */

.error-message {
  font-size: 0.688rem;
  font-weight: 600;
  color: "#FF543E";
}

.recaptcha-checkbox-border{
  /* max-width: 600px !important; */
  /* border: 1px solid red !important; */
}

.disable {
  background: var(--BG-50);
  color: var(--disable-color);
  font-size: 0.938rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: not-allowed;
}

.popover-overlay-custom {
  position: absolute;
  top: 100px;
  left: 600px !important;
}

.popover-overlay-custom1 {
  position: absolute;
  top: 600px;
  left: 600px !important;
}
